








































import { Component, Prop, Vue } from 'vue-property-decorator'
import ColoredBox from '@/components/atoms/ColoredBox.vue'
import ImageRound from '@/components/atoms/ImageRound.vue'
import LabelBase from '@/components/atoms/LabelBase.vue'

@Component({
  components: {
    ColoredBox,
    ImageRound,
    LabelBase,
  },
})
export default class StudentInformationTimeline extends Vue {
  @Prop()
  activity!: {}

  @Prop({ default: true })
  isTeacher?: boolean

  private drillResultUrl(activity: any): string {
    if (this.isTeacher) {
      if (activity.resultDrillVersion === 2) {
        return `/teacher/v2/history/drillResult?resultDrillId=${activity.resultDrillId}&prevPage=Timeline`
      } else {
        return `/teacher/history/drillResult/${activity.resultDrillId}?prevPage=Timeline`
      }
    } else {
      if (activity.resultDrillVersion === 2) {
        return `/student/v2/history/drillResult?resultDrillId=${activity.resultDrillId}&prevPage=StudentTimeline`
      } else {
        return `/student/history/drillResult/${activity.resultDrillId}?prevPage=StudentTimeline`
      }
    }
  }
}
